import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Swiper, Billboard, H1, H2 } from '@raysync/common/components'
import { BannerContainer, BannerWrap } from './atoms'
import { useMedia } from '@raysync/common/hooks'

export const Banner = ({ asText }) => {
  const isMobile = useMedia()
  return (
    <StaticQuery
      query={graphql`
        query {
          banner: file(relativePath: { regex: "/example/banner.png/" }) {
            ...fluidImage
          }
          mobileBanner: file(relativePath: { regex: "/example/banner_m/" }) {
            ...fluidImage
          }
        }
      `}
      render={data => {
        const bannerImage = isMobile ? data.mobileBanner.childImageSharp : data.banner.childImageSharp
        return (
          <Swiper>
            <BannerContainer>
              <BannerWrap>
                {asText ? (
                  <>
                    <p>
                      加速数据新价值
                      <br />
                      赋能企业新未来
                    </p>
                    <span>—— 世界500强企业优选的数据传输软件厂商</span>
                  </>
                ) : (
                  <>
                    <H1>
                      加速数据新价值
                      <br />
                      赋能企业新未来
                    </H1>
                    <H2>—— 世界500强企业优选的数据传输软件厂商</H2>
                  </>
                )}
              </BannerWrap>
              <Billboard fluid={bannerImage.fluid} />
            </BannerContainer>
          </Swiper>
        )
      }}
    />
  )
}
