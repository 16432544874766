import styled from 'styled-components'
import { Wrapper } from '@raysync/common/components'
import { color, Media } from '@raysync/common/theme'

export const BannerWrap = styled(Wrapper)`
  height: 100%;
  color: ${p => p.theme.color.textDarkPlus};
  h1,
  p {
    font-weight: bold;
    line-height: 1.5;
    font-size: 60px;
    margin: 130px 0 25px;
  }
  h2,
  span {
    display: inline-block;
    font-size: 24px;
  }
  ${Media.phone`
    padding: 0 .5rem;
    h1,p {
      font-size: .8rem;
      text-align: center;
      margin-top: 1.95rem;
    }
    h2,span {
      font-size: .32rem;
      text-align: center;
    }
  `}
`
export const BannerContainer = styled.div`
  position: relative;
  height: 500px;
  overflow: hidden;
  z-index: 0;
  background-color: ${color.panel};
  ${Media.phone`
    height: 9rem;
  `}
`
export const ExampleWrapper = styled(Wrapper)`
  padding-bottom: 90px;
  ${Media.phone`
    padding-bottom: 0.9rem;
  `}
`
