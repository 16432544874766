import React from 'react'
import styled, { css } from 'styled-components'
import { Media } from '@raysync/common/theme'
import { Link } from '@raysync/common/components'
import { useMedia } from '@raysync/common/hooks'

const ActivedStyle = css`
  color: ${p => p.theme.color.secondary};
  box-shadow: 0px 2px 20px 0px #e6e6e6;
  border-radius: 6px;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0 24px;
  ${Media.phone`
    display: block;
    padding: .1rem 0 .2rem;
  `}
`
const AllBox = styled.div`
  min-width: 226px;
  display: flex;
  font-size: 16px;
  line-height: 46px;
  p {
    font-weight: bold;
  }
  a {
    display: inline-block;
    width: 90px;
    height: 46px;
    text-align: center;
    margin: 0 28px;
    cursor: pointer;
    transition: all linear 0.3s;
    :hover {
      ${ActivedStyle}
    }
    ${p => p.actived && ActivedStyle}
  }
  ${Media.phone`
    display: block;
  `}
`
const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
`
const Item = styled.div`
  width: 160px;
  color: ${p => p.theme.color.textDarkPlus};
  a {
    display: inline-flex;
    align-items: center;
    margin-bottom: 12px;
    padding: 11px 16px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    span {
      font-size: 16px;
    }
    cursor: pointer;
    transition: all linear 0.3s;
    :hover {
      ${ActivedStyle}
    }
    ${p => p.actived && ActivedStyle}
  }
  ${Media.phone`
    width: 31%;
    :not(:nth-child(3n)) {
      margin-right: .2rem;
    }
    a {
      font-size: .28rem;
      display: flex;
      justify-content: ${p => (p.slug === 'all' ? 'center' : 'flex-start')};
      box-shadow: 0px 2px 20px 0px #e6e6e6;
      border-radius: 6px;
      padding: .2rem .16rem .2rem .2rem;
      span {
        font-size: .3rem;
      }
      img {
        width: .28rem;
        height: .28rem;
        margin-right: .08rem;
      }
    }
  `}
`

export const Categories = ({ categories }) => {
  const isMobile = useMedia()
  const getActived = hash => {
    if (typeof window !== 'undefined') {
      if (!window.location.hash && ['/example', '/example/'].includes(window.location.pathname) && hash === '#all') {
        return true
      }
      return window.location.hash === hash
    }
    return false
  }
  const items = React.useMemo(() => {
    let arr = [...categories]
    if (isMobile) {
      arr.unshift({ name: '全部', slug: 'all' })
    }
    return arr
  }, [isMobile, categories])
  return (
    <Container>
      <AllBox actived={getActived('#all')}>
        <p>按行业查看</p>
        {!isMobile && <Link to='/example#all'>全部</Link>}
      </AllBox>
      <List>
        {items.map(category => (
          <Item key={category.slug} slug={category.slug} actived={getActived(`#${category.slug}`)}>
            <Link to={`/example#${category.slug}`}>
              {category.icon && <img src={category.icon.file.url} alt={category.name} />}
              <span>{category.name}</span>
            </Link>
          </Item>
        ))}
      </List>
    </Container>
  )
}
