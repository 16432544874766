import React from 'react'
import intl from 'react-intl-universal'
import { InviteBanner } from '../../components/banners'
import { Swiper, SEO } from '@raysync/common/components'
import Layout from '../../components/layout'
import { Banner } from './banner'
import { Categories } from './categories'
import { ExampleWrapper } from './atoms'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { Media } from '@raysync/common/theme'
import { H1 } from '@raysync/common/components'

const Container = styled.div`
  padding: 18px 40px 80px 30px;
  box-shadow: 0px 2px 40px 0px #e6e6e6;
  border-radius: 16px;
  color: ${p => p.theme.color.textDarkPlus};
  margin-bottom: 30px;
  h1 {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    margin: 36px 0 22px;
  }
  ${Media.phone`
    padding: .3rem .3rem .9rem;
    margin-bottom: .6rem;
    border-radius: .16rem;
    h1 {
      line-height: 1.4;
      font-size: .36rem;
      margin: .45rem 0 .3rem;
    }
  `}
`
const Content = styled.div`
  color: #636772;
  p {
    line-height: 36px;
    padding: 0 15px;
    img {
      position: relative;
      max-width: 100%;
    }
  }
  strong,
  strong span {
    font-weight: bold !important;
  }
  ${Media.phone`
    p {
       line-height: 1.8;
       padding: 0;
    }
  `}
`
const Category = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  img {
    margin-right: 10px;
  }
`
const CodeBlock = props => {
  return <span dangerouslySetInnerHTML={{ __html: props.value }}></span>
}
function ExampleDetail(props) {
  const {
    location,
    pathContext: { categories, detail, seoTitle, seoKeywords, seoDescription },
  } = props
  const contentRef = React.useRef(null)
  const category = categories.find(item => item.slug === detail.category.slug)
  const categoryIcon = category.icon.file.url
  const categoryName = category.name

  return (
    <Layout location={location} showH1={false}>
      <SEO
        title={`${seoTitle || detail.title}-镭速企业大文件传输软件`}
        description={seoDescription || intl.get('example.seo.desc')}
        keywords={seoKeywords || intl.get('example.seo.keywords')}
      />
      <Banner asText />
      <ExampleWrapper>
        <Categories categories={categories} />
        <Container>
          <Category>
            <img src={categoryIcon} alt={categoryName} />
            <p>{categoryName}</p>
          </Category>
          <H1>{detail.title}</H1>

          {detail.content && (
            <Content ref={contentRef}>
              <ReactMarkdown source={detail.content.content} escapeHtml={false} renderers={{ code: CodeBlock }} />
            </Content>
          )}
        </Container>
      </ExampleWrapper>
      <Swiper>
        <InviteBanner type='example' />
      </Swiper>
    </Layout>
  )
}

export default ExampleDetail
